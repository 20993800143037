<template>
	<div></div>
</template>
<script>
export default {
	name: "",
	components: {},
	mixins: [],
	props: {},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	created() {
      this.autoLogin()
	},
	mounted() {
   },
	methods: {
		autoLogin() {
         const loading = this.$loading({
			lock: true,
			text: "登录中...",
			spinner: "el-icon-loading",
			background: "rgba(255, 255, 255, 0.3)",
			fullscreen: false,
		});
			let token = location.href.split("token=")[1];
			let params = {
				token: token,
			};
			this.$api.user
				.autoLogin(params)
				.then(res => {
					this.$session.setLoginToken(res.data.access_token);
					// 获取用户信息
					this.$api.personalCenter
						.getInfo()
						.then(user => {
							this.$session.setUsers(user.data);
							this.$message.success("恭喜您，登录成功！");
							this.$router.push("/home");
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						})
						.finally(() => {
							loading.close();
						});
				})
				.catch(err => {
					this.$message.error(err?.msg);
				})
				.finally(() => {
					loading.close();
					this.$router.push("/home");
				});
		},
	},
};
</script>
<style lang="less">
@import "./index.less";
</style>
